import React, { useState, useEffect, Suspense, lazy, createContext, FC } from 'react'
import { Route, Routes, HashRouter } from 'react-router-dom'
import { Socket } from 'socket.io-client'
import ReactGA from 'react-ga'

import Loader from './components/ui/Loader'
import initialSkt from './lib/socket'

import './styles/App.scss'

const Console = lazy(() => import('./components/console/Console'))
const LandingPage = lazy(() => import('./components/LandingPage/LandingPage'))
const TheLayout = lazy(() => import('./containers/TheLayout'))
const DrawingView = lazy(() => import('./components/drawing/DrawingView'))

export const socketContext = createContext({ socket: initialSkt, socketOn: false, socketId: '' })

const App: FC = () => {
    const [socket, setSocket] = useState<Socket>(initialSkt)
    const [socketOn, setSocketOn] = useState(false)
    const [socketId, setSocketId] = useState('')
    const [eventSlug, setEventSlug] = useState(sessionStorage.getItem('eventSlug') || '')
    // 取得 strapi 內這場活動的資料
    const [data, setData] = useState();
    // 取得 strapi 所有活動的 event id list
    const [eventList, setEventList] = useState([]);
    // 取得 console 中選擇的 evenid
    const [eventIdConsole, setEventIdConsole] = useState('');
    // 取得 strapi console 選擇的這場活動資料
    const [dataConsole, setDataConsole] = useState();


    // 隨著網址 eventSlug 變動，重新取得網站資料
    useEffect(() => {
        if (eventSlug) {
            fetch('https://proj.uppcdn.net/api/amplify-event-edteches')
            .then(response => response.json())
            .then(data => {
                const eventData = data.data.filter(item => item.attributes.eventId === eventSlug)
                if(eventData[0]){
                    setData(eventData);
                }
                const eventListData = data.data.map(item => item.attributes.eventId)
                if(eventListData[0]){
                    setEventList(eventListData);
                }
            })
            .catch(error => console.error(error));
        }
    }, [eventSlug]);

    useEffect(() => {
        if (eventIdConsole) {
            fetch('https://proj.uppcdn.net/api/amplify-event-edteches')
            .then(response => response.json())
            .then(data => {
                const DataConsoleData = data.data.filter(item => item.attributes.eventId === eventIdConsole)
                if(DataConsoleData[0]){
                    setDataConsole(DataConsoleData);
                }
            })
            .catch(error => console.error(error));
        }
    }, [eventIdConsole]);

    ReactGA.initialize(process.env.REACT_APP_GA_ID as string)
    ReactGA.pageview(window.location.pathname + window.location.search)

    useEffect(() => {
        if (initialSkt) setSocket(initialSkt)

        return () => {
            initialSkt.close()
        }
    }, [setSocket])

    useEffect(() => {
        if (socket) {
            socket.on('connect', () => {
                console.log({ status: 'socket connected', socketId: socket.id })
                setSocketId(socket.id)
                setSocketOn(true)
            })

            socket.on('disconnect', () => {
                console.log({ status: 'socket disconnected' })
                setSocketId('')
                setSocketOn(false)
            })
        }
    }, [socket])

    return (
        <div className="App">
            <HashRouter>
                <Suspense fallback={<Loader />}>
                    <socketContext.Provider value={{ socket, socketOn, socketId }}>
                        <Routes>
                            <Route path="/*" element={<LandingPage data={data} setEventSlug={setEventSlug} eventSlug={eventSlug} eventList={eventList} />}/>
                            <Route path="/main/*" element={<TheLayout data={data} eventSlug={eventSlug}/>} />
                            <Route path="/console/*" element={<Console setEventIdConsole={setEventIdConsole} dataConsole={dataConsole} />} />
                            <Route path="/drawing" element={<DrawingView />} />
                            <Route path="/404" element={<div>404 not found</div>} />
                        </Routes>
                    </socketContext.Provider>
                </Suspense>
            </HashRouter>
        </div>
    )
}

export default App
