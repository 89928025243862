import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { Winner } from '../../lib/socketDataTypes'
import axios from '../../lib/axios'

export type Award = {
    _id?: string
    award: string
    prize: string
    count: number
    path: string
    eventId: string
    winners: Winner[]
}

interface DrawingState {
    awards: Award[]
    message: string
    status: string
}

export const onPostAward = createAsyncThunk<{ award: Award; status: string; message: string }, { awardPkg: Award }>(
    'drawing/postAward',
    async (args, { rejectWithValue }) => {
        try {
            const res = await axios.post('/drawing/award', { awardPkg: args.awardPkg })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onGetAwards = createAsyncThunk<{ awards: Award[]; status: string; message: string }>(
    'drawing/getAwards',
    async (args, { rejectWithValue }) => {
        try {
            const res = await axios.get('/drawing/awards')
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onDeleteAward = createAsyncThunk<{ deletedId: string; status: string; message: string }, { _id: string }>(
    'drawing/deleteAward',
    async (args, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`/drawing/award?aid=${args._id}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onResetDrawing = createAsyncThunk<{ status: string; message: string }>(
    'drawing/reset',
    async (args, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/drawing/reset`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: DrawingState = { awards: [], status: '', message: '' }

export const drawingSlice = createSlice({
    name: 'drawing',
    initialState,
    reducers: {
        initDrawing(state) {
            state.status = ''
            state.message = ''
        },
        updateWinners(state, action) {
            const clonedState = [...state.awards]

            clonedState.forEach((award) => {
                if (award._id === action.payload.awardPkg._id) {
                    award.winners = action.payload.winners
                }
            })

            state.awards = clonedState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(onPostAward.fulfilled, (state, action) => {
                state.awards = [...state.awards, action.payload.award]
                state.status = action.payload.status
                state.message = action.payload.message
            })
            .addCase(onPostAward.rejected, (state, action) => {
                state.message = (action.payload as { error: string }).error
            })
            .addCase(onGetAwards.fulfilled, (state, action) => {
                state.awards = action.payload.awards
                state.status = action.payload.status
            })
            .addCase(onGetAwards.rejected, (state, action) => {
                state.message = (action.payload as { error: string }).error
            })
            .addCase(onDeleteAward.fulfilled, (state, action) => {
                const updatedAwards = state.awards.filter((award) => {
                    return award._id !== action.payload.deletedId
                })

                state.awards = updatedAwards
                state.status = action.payload.status
                state.message = action.payload.message
            })
            .addCase(onDeleteAward.rejected, (state, action) => {
                state.message = (action.payload as { error: string }).error
            })
            .addCase(onResetDrawing.fulfilled, (state, action) => {
                state.status = action.payload.status
                state.message = action.payload.message

                const clonedState = [...state.awards]

                clonedState.forEach((award) => {
                    award.winners = []
                })

                state.awards = clonedState
            })
            .addCase(onResetDrawing.rejected, (state, action) => {
                state.message = (action.payload as { error: string }).error
            })
    },
})

export const { initDrawing, updateWinners } = drawingSlice.actions
export default drawingSlice.reducer
